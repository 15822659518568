import { TransactionSlice } from "@/interfaces/data";
import { createSlice } from "@reduxjs/toolkit";
import { PaymentIntent } from "@stripe/stripe-js";
import { PayloadAction } from "@reduxjs/toolkit";

const initialState: TransactionSlice = {
  stripeClientSecret: null,
};

const transactionSlice = createSlice({
  name: "Transaction",
  initialState,
  reducers: {
    onRetrieveStripeClientSecret(
      state,
      action: PayloadAction<PaymentIntent["client_secret"]>
    ) {
      state.stripeClientSecret = action.payload;
    },
    onClearState(state) {
      state.stripeClientSecret = null;
    },
  },
});

export const { onRetrieveStripeClientSecret, onClearState } =
  transactionSlice.actions;
export default transactionSlice.reducer;
