import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { throttle } from "lodash";
import userReducer from "./user/userSlice";
import appReducer from "./app/appSlice";
import transactionReducer from "./transaction/transactionSlice";
import cardReducer from "./card/cardSlice";

export const localStorageKey = "lynknow-1.0";

const saveStateToLocalStorage = (state: RootState["user"]) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(localStorageKey, serializedState);
  } catch {}
};

const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem(localStorageKey);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const persistedUserState = loadStateFromLocalStorage();

const userReducers = combineReducers({
  data: userReducer,
});

export const store = configureStore({
  reducer: {
    user: userReducers,
    app: appReducer,
    transaction: transactionReducer,
    card: cardReducer,
  },
  preloadedState: {
    user: persistedUserState,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

store.subscribe(
  throttle(() => saveStateToLocalStorage(store.getState().user), 1000)
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
