import { CardSlice } from "@/interfaces/data";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: CardSlice = {
  share: {
    folderName: null,
    exchangePromptOpen: false,
    submitting: false,
  },
};

const cardSlice = createSlice({
  name: "Card",
  initialState,
  reducers: {
    onChangeSharingFolder(state, action: PayloadAction<string>) {
      state.share.folderName = action.payload;
    },
    onOpenExchangePrompt(state) {
      state.share.exchangePromptOpen = true;
    },
    onCloseExchangePrompt(state) {
      state.share.exchangePromptOpen = false;
    },
    onSharingSubmit(state) {
      state.share.submitting = true;
    },
    onSharingCompleted(state) {
      state.share.submitting = false;
    },
  },
});

export const {
  onChangeSharingFolder,
  onCloseExchangePrompt,
  onOpenExchangePrompt,
  onSharingCompleted,
  onSharingSubmit,
} = cardSlice.actions;
export default cardSlice.reducer;
