import { CardViewDef, ContactsPageViewDef } from "@/interfaces/components";
import {
  AppSlice,
  CardProfileViewDef,
  CardSetupViewDef,
} from "@/interfaces/data";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: AppSlice = {
  menubarOpen: false,
  cardSetupView: "personal-profile",
  cardView: "front",
  cardProfileView: "my-profile",
  actionModalOpen: false,
  contactInfoOpen: false,
  QRModalCardURL: null,
  contactsPageView: "personal",
  contactsNewFolderOpen: false,
  assignEmployeeCardURL: null,
  deactivateEmployeeCardURL: null,
  emailSentModalOpen: false,
  emailContactModalOpen: false,
  employeeLeaveModalOpen: false,
  shareContactModalOpen: false,
};

const appSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    onOpenEmployeeLeaveModal(state) {
      state.employeeLeaveModalOpen = true;
    },
    onCloseEmployeeLeaveModal(state) {
      state.employeeLeaveModalOpen = false;
    },
    onOpenEmailSentModal(state) {
      state.emailSentModalOpen = true;
    },
    onOpenShareContactModal(state) {
      state.shareContactModalOpen = true;
    },
    onCloseShareContactModal(state) {
      state.shareContactModalOpen = false;
    },
    onOpenEmailContactModal(state) {
      state.emailContactModalOpen = true;
    },
    onCloseEmailContactModal(state) {
      state.emailContactModalOpen = false;
    },
    onCloseEmailSentModal(state) {
      state.emailSentModalOpen = false;
    },
    onOpenAssignEmployeeModal(state, action: PayloadAction<string>) {
      state.assignEmployeeCardURL = action.payload;
    },
    onCloseAssignEmployeeModal(state) {
      state.assignEmployeeCardURL = null;
    },
    onOpenDeactivateEmployeeModal(state, action: PayloadAction<string>) {
      state.deactivateEmployeeCardURL = action.payload;
    },
    onCloseDeactivateEmployeeModal(state) {
      state.deactivateEmployeeCardURL = null;
    },
    onOpenContactNewFolder(state) {
      state.contactsNewFolderOpen = true;
    },
    onCloseContactNewFolder(state) {
      state.contactsNewFolderOpen = false;
    },
    onOpenQRModal(state, action: PayloadAction<string>) {
      state.QRModalCardURL = action.payload;
    },
    onChangeContactsPageView(
      state,
      action: PayloadAction<ContactsPageViewDef>
    ) {
      state.contactsPageView = action.payload;
    },
    onCloseQRModal(state) {
      state.QRModalCardURL = null;
    },
    onOpenContactInfo(state) {
      state.contactInfoOpen = true;
    },
    onCloseContactInfo(state) {
      state.contactInfoOpen = false;
    },
    onOpenActionModal(state) {
      state.actionModalOpen = true;
    },
    onCloseActionModal(state) {
      state.actionModalOpen = false;
    },
    onOpenMenubar(state) {
      state.menubarOpen = true;
    },
    onCloseMenubar(state) {
      state.menubarOpen = false;
    },
    onChangeCardSetupView(state, action: PayloadAction<CardSetupViewDef>) {
      state.cardSetupView = action.payload;
    },
    onChangeCardView(state, action: PayloadAction<CardViewDef>) {
      state.cardView = action.payload;
    },
    onChangeCardProfileView(state, action: PayloadAction<CardProfileViewDef>) {
      state.cardProfileView = action.payload;
    },
  },
});

export const {
  onChangeCardProfileView,
  onOpenMenubar,
  onCloseMenubar,
  onCloseEmployeeLeaveModal,
  onOpenEmployeeLeaveModal,
  onOpenActionModal,
  onCloseEmailContactModal,
  onCloseShareContactModal,
  onOpenShareContactModal,
  onCloseDeactivateEmployeeModal,
  onOpenDeactivateEmployeeModal,
  onOpenEmailContactModal,
  onCloseActionModal,
  onCloseAssignEmployeeModal,
  onOpenAssignEmployeeModal,
  onCloseEmailSentModal,
  onOpenEmailSentModal,
  onChangeCardSetupView,
  onCloseQRModal,
  onOpenQRModal,
  onChangeContactsPageView,
  onCloseContactNewFolder,
  onOpenContactNewFolder,
  onChangeCardView,
  onCloseContactInfo,
  onOpenContactInfo,
} = appSlice.actions;
export default appSlice.reducer;
