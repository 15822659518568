import { FetchNotificationsPayload, UserSlice } from "@/interfaces/data";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: UserSlice = {
  token: null,
  refreshToken: null,
  id_role: null,
};

const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    onRetrieveToken(state, action: PayloadAction<UserSlice>) {
      state.token = action.payload.token;
      state.id_role = action.payload?.id_role;
      state.refreshToken = action.payload?.refreshToken;
    },
    onLogout(state) {
      state.token = null;
      state.refreshToken = null;
      state.id_role = null;
    },
  },
});

export const { onRetrieveToken, onLogout } = userSlice.actions;
export default userSlice.reducer;
